import { Box, LinearProgress, makeStyles } from "@material-ui/core"
import { CheckCircleOutlineOutlined, CloseOutlined } from "@material-ui/icons"
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked"
import React, { useEffect, useState } from "react"
import { Enumobj as status } from ".."
import * as styles from "../../conversion/conversion_new.module.scss"

const Validation = props => {
  const [linear, setLinear] = React.useState(0)
  const [displayMessage, setDisplayMessage] = useState(
    "No need to wait, an email notification will be sent to",
  )
  const [email, setEmail] = useState(props.email)
  const [displayMessagePart, setDisplayMessagePart] = useState("with status update.")
  const [displayMessageTwo, setDisplayMessageTwo] = useState("")
  const [valueBuffer, setValueBuffer] = React.useState(25)
  const useStyles = makeStyles(() => ({
    root: {
      margin: "85px auto",

      "& .MuiLinearProgress-root": {
        height: "15px",
      },
      "& .MuiLinearProgress-dashed": {
        backgroundColor: "#ffffff",
      },
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#0075db",
      },
    },
  }))

  const [currentCount, setCount] = useState(1)

  const timer = () => setCount(currentCount + 1)

  useEffect(() => {
    if (currentCount <= 0) {
      return
    }
    if (currentCount >= 15 && props.conversion_status === status.ERROR) {
      props.setRefresh(status.INITIAL)
    }
    const id = setInterval(timer, 1000)
    return () => clearInterval(id)
  }, [currentCount]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderStatusDetails = () => {
    return (
      <div className={styles.progressindicator}>
        <div>{status.FORMAT ? "Formatting" : ""}</div>
        <div>{status.VALIDATE ? "Validating" : ""}</div>
        <div>{status.CONVERTING ? "Converting" : ""}</div>
        <div>{status.COMPLETED ? "Completing" : ""}</div>
      </div>
    )
  }

  useEffect(() => {
    switch (props.conversion_status) {
      default:
      case status.FORMAT:
        setLinear(0)
        setValueBuffer(33)
        setEmail(props.email)
        setDisplayMessagePart("with status update.")
        setDisplayMessageTwo("Upload accepted.  File format review in progress.")
        break
      case status.VALIDATE:
        setLinear(33)
        setValueBuffer(66)
        setDisplayMessage("No need to wait, an email notification will be sent to")
        setEmail(props.email)
        setDisplayMessageTwo("Upload accepted. File validation in progress.")
        break
      case status.CONVERTING:
        setLinear(66)
        setValueBuffer(86)
        setEmail(props.email)
        setDisplayMessageTwo(
          "Upload accepted. File validation complete. Account numbers downloading to system.",
        )
        break
      case status.ERROR:
        setLinear(100)
        setValueBuffer(100)
        setEmail(props.email)
        setDisplayMessageTwo("File Validation Failed")
        break
    }
  }, [props.conversion_status]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderCircleElement = () => {
    switch (props.conversion_status) {
      default:
      case status.FORMAT:
        return (
          <>
            {" "}
            <div className={styles.progressindicator}>
              <CheckCircleOutlineOutlined />
              <CircleUnchecked />
              <CircleUnchecked />
              <CircleUnchecked />
            </div>
            {renderStatusDetails()}
          </>
        )

      case status.VALIDATE:
        return (
          <>
            {" "}
            <div className={styles.progressindicator}>
              <CheckCircleOutlineOutlined />
              <CheckCircleOutlineOutlined />
              <CircleUnchecked />
              <CircleUnchecked />
            </div>
            {renderStatusDetails()}
          </>
        )

      case status.CONVERTING:
        return (
          <>
            {" "}
            <div className={styles.progressindicator}>
              <CheckCircleOutlineOutlined />
              <CheckCircleOutlineOutlined />
              <CheckCircleOutlineOutlined />
              <CircleUnchecked />
            </div>
            {renderStatusDetails()}
          </>
        )
      case status.ERROR:
        return (
          <>
            {" "}
            <div className={styles.progressindicator}>
              <CheckCircleOutlineOutlined />
              <CheckCircleOutlineOutlined />
              <CloseOutlined className={styles.closeoutline} />
            </div>
            <div className={styles.progressindicator}>
              <div>{status.FORMAT ? "Formatting" : ""}</div>
              <div>{status.VALIDATE ? "Validating" : ""}</div>
              <div>{status.ERROR ? "Error" : ""}</div>
            </div>
          </>
        )
    }
  }

  const classes = useStyles()

  return (
    <div className={styles.content}>
      <div className={styles.row_1}>
        <Box className={styles.validatorbox}>
          {displayMessage}{" "}
          <a href="/#" className={styles.viewTemplates}>
            {email}
          </a>{" "}
          {displayMessagePart}
          <div className={styles.displayMessageTwo}>{displayMessageTwo} </div>
          <div className={classes.root}>
            <LinearProgress variant="buffer" value={linear} valueBuffer={valueBuffer} />
            {renderCircleElement()}
          </div>
        </Box>
      </div>
    </div>
  )
}

export default Validation
