import React, { useCallback, useState, useContext } from "react"
import { useDropzone } from "react-dropzone"
import * as styles from "./deluxeFront.module.scss"
import DefaultImgPreviewUrl from "../../../../images/deluxe-upload-with-canvas.png"
import IconFigma from "../../../../images/icons/icon-figma.png"
import IconSketch from "../../../../images/icons/icon-sketch.png"
import IconPS from "../../../../images/icons/icon-ps.png"
import IconAI from "../../../../images/icons/icon-ai.png"
import IconPNG from "../../../../images/icons/icon-png.png"
import SafeAreaPreview from "../../../../images/Safe-Area-Preview.png"
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Switch,
} from "@material-ui/core"
import { uploadDeluxeImage } from "../../../../services/card"
import { processUploadedArtwork } from "../../../../graphql/mutations"
import { Amplify, API, graphqlOperation } from "aws-amplify"
import { initConfig } from "../../../../services/amplify"
import { AppDispatchContext, AppStateContext } from "../../../../context/appContext"
import { getPricingByCategory } from "../../../../services/pricing"
import { UPDATE_CARD_FRONT, UPDATE_NOTIFICATION } from "../../../../constants/actionTypes"
import { navigate } from "gatsby"
import Notification from "../../../notification"
import { OrderNavigation } from "../../../orderNavigation"

Amplify.configure(initConfig())

const DeluxeFrontPage = () => {
  const dispatch = useContext(AppDispatchContext)
  const state = useContext(AppStateContext)

  const isDesignReOrder = state.order.cards.subCardType === "designer"
  const [loadingAnimation, setLoadingAnimation] = useState(false)
  const [textSafeArea, setTextSafeArea] = useState(true)
  const [amountOfCards, setAmountOfCards] = useState(state.order.cards.amountOfCards || 1000)
  const [cardFrontDeluxe, setCardFrontDeluxe] = useState({
    imgData: state.order.cards.cardFront.imgData || DefaultImgPreviewUrl,
  })
  const [buttonDisabled, setButtonDisabled] = useState(state.order.cards.cardFront.imgData === "")

  const pricing = getPricingByCategory("card", "deluxe", state.authProvider)
  const nextLabel = "Card Back"
  const prevLabel = "Back: Dashboard"

  // Warn users before: reload, close window, close tab
  window.addEventListener("beforeunload", function (e) {
    const pathname = window.location.pathname
    //Paths for orders: card-front, card-back accessories shipping review-order payment
    if (
      pathname === "/app/order/card-front" ||
      pathname === "/app/order/card-front-deluxe" ||
      pathname === "/app/order/designer" ||
      pathname === "/app/order/card-back" ||
      pathname === "/app/order/accessories" ||
      pathname === "/app/checkout/shipping" ||
      pathname === "/app/checkout/review-order" ||
      pathname === "/app/checkout/payment" ||
      pathname === "/app/checkout/payment/" ||
      pathname === "/app/order/designer"
    ) {
      e.returnValue = ""
    }
  })

  const quantities = pricing.reduce(
    (result, price) => {
      const { quantity, total_price } = price
      return {
        ...result,
        [quantity]: {
          value: quantity,
          label: quantity,
          cost: total_price,
        },
      }
    },
    {
      /* initial value */
    },
  )

  const onDrop = useCallback(
    async acceptedFiles => {
      await acceptedFiles.map(file => {
        setLoadingAnimation(true)

        if (file.type !== "image/png") {
          setLoadingAnimation(false)
          return dispatch({
            type: UPDATE_NOTIFICATION,
            payload: {
              show: true,
              type: `error`,
              text: `Please make sure your file type is a PNG`,
            },
          })
        }

        if (file.size > 104857600) {
          setLoadingAnimation(false)
          return dispatch({
            type: UPDATE_NOTIFICATION,
            payload: {
              show: true,
              type: `error`,
              text: `File size is larger than 10mb`,
            },
          })
        }

        uploadDeluxeImage(file).then(artWorkUrl => {
          API.graphql(graphqlOperation(processUploadedArtwork, { artworkUrl: artWorkUrl }))
            .then(result => {
              setCardFrontDeluxe(prevState => {
                return {
                  ...prevState,
                  imgData: result.data.processUploadedArtwork.artworkUrl,
                  uploaded: true,
                }
              })
              dispatch({
                type: UPDATE_NOTIFICATION,
                payload: {
                  show: true,
                  type: `success`,
                  text: `Upload successful`,
                },
              })
              setLoadingAnimation(false)
              setButtonDisabled(false)
            })
            .catch(err => {
              dispatch({
                type: UPDATE_NOTIFICATION,
                payload: {
                  show: true,
                  type: `error`,
                  text: `Upload Error. ${err.errors[0].message}`,
                },
              })
              setLoadingAnimation(false)
            })
        })

        return ``
      })
    },
    [dispatch],
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: { "image/png": [".png"] },
  })

  const toggleSafeTextArea = () => {
    setTextSafeArea(!textSafeArea)
  }

  const navToCardBack = () => {
    dispatch({
      type: UPDATE_CARD_FRONT,
      payload: {
        cardType: "deluxe",
        amountOfCards,
        cost: quantities[amountOfCards].cost,
        cardFront: {
          imgData: cardFrontDeluxe.imgData,
        },
      },
    })
    navigate("../card-back")
  }

  const navigateBack = () => {
    navigate("/app/dashboard")
  }

  const showCardAmounts = () => {
    return (
      <div className={styles.amountOfCards}>
        <FormControl variant="outlined">
          <InputLabel shrink id="amountLabel">
            Amount of Cards
          </InputLabel>
          <Select
            required
            autoWidth={true}
            variant="outlined"
            id="amount"
            label="Number of cards"
            name="amount"
            value={amountOfCards}
            onChange={e => {
              setAmountOfCards(e.target.value)
            }}
          >
            {Object.values(quantities).map((key, index) => {
              return (
                <MenuItem key={index} id={key.value} value={key.value}>
                  {key.label + " - $" + (key.cost / 100).toFixed(2)}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    )
  }

  const updateSafeAreaLine = (hex, selector) => {
    let elements = document.querySelectorAll("[class*=textSafeArea]")
    for (let i = 0; i < elements.length; i++) {
      if (elements[i]) {
        elements[i].style.stroke = hex
      }
    }
    //reset selector outlines
    document.getElementById("redSelect").style.stroke = "#474747"
    document.getElementById("blackSelect").style.stroke = "#474747"
    document.getElementById("whiteSelect").style.stroke = "#474747"
    document.getElementById("redSelect").style.strokeWidth = "1px"
    document.getElementById("blackSelect").style.strokeWidth = "1px"
    document.getElementById("whiteSelect").style.strokeWidth = "1px"
    //set distinguished selector outline
    document.getElementById(selector).style.stroke = "#62B4FF"
    document.getElementById(selector).style.strokeWidth = "2px"
  }

  if (isDesignReOrder) {
    return (
      <>
        <section className={styles.sectionContainer}>
          <div className={styles.sectionLeft}>
            <h4 className={styles.sectionTitle}>card front</h4>
            {showCardAmounts()}
            <p>
              Image Dimensions: 1088px width x 713px height <br />
              Accepted File Types: PNG | Max File Size: 100MB
            </p>
            <p>
              Note: Please make sure text or logo is within the safe area. The Canvas area outside
              the Card Outline is intended only to allow the full color printing to the edge of the
              Card Outline. To capture the full color, provide a .PNG flat art file. Uploading a
              layered file will result in color shifting at the time of printing.
            </p>
            <div className={styles.downloadSection}>
              <p>Download a gift card design template by selecting an option below.</p>
              <div className={styles.download}>
                <a href={"/deluxe-design-templates/figma-gift-card-design-template.fig"} download>
                  <img src={IconFigma} alt="Download a sketch figma design template" />
                </a>
                <a
                  href={"/deluxe-design-templates/sketch-gift-card-design-template.sketch"}
                  download
                >
                  <img src={IconSketch} alt="Download a sketch design template" />
                </a>
                <a
                  href={"/deluxe-design-templates/photoshop-gift-card-design-template.psd"}
                  download
                >
                  <img src={IconPS} alt="Download an Adobe photoshop design template" />
                </a>
                <a
                  href={"/deluxe-design-templates/illustrator-gift-card-design-template.ai"}
                  download
                >
                  <img src={IconAI} alt="Download an Adobe Illustrator design template" />
                </a>
                <a href={"/deluxe-design-templates/gift-card-design-template-blank.png"} download>
                  <img src={IconPNG} alt="Download a blank PNG design template" />
                </a>
              </div>
              <div id="safeAreaTemplate">
                <p className={styles.sampleCardParameters} tabIndex={0}>
                  View the Canvas, Card Outline, and Safe Area below:
                </p>
                <img src={SafeAreaPreview} alt="Gift Card safe and bleed design template" />
              </div>
            </div>
          </div>
          <div className={styles.sectionRight}>
            <div className={styles.safeAreaControl}>
              {isDragActive ? (
                <p>Drop your file on top of the card ...</p>
              ) : (
                <p>Drag &amp; drop your file onto the card or click it to select</p>
              )}
              <div className={styles.safeAreaColorSelection}>
                <Switch
                  checked={textSafeArea}
                  onChange={toggleSafeTextArea}
                  name="safeAreaToggle"
                  data-test="safe-area-toggle"
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <span>show safe area</span>
                {textSafeArea ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60px"
                    height="20px"
                    viewBox="0 0 60 20"
                  >
                    <rect
                      id="redSelect"
                      x="5"
                      width="15px"
                      height="15px"
                      fill="#db5559"
                      stroke="#62B4FF"
                      strokeWidth="2px"
                      onClick={() => updateSafeAreaLine("#db5559", "redSelect")}
                    />
                    <rect
                      id="blackSelect"
                      x="25"
                      width="15px"
                      height="15px"
                      fill="#000"
                      onClick={() => updateSafeAreaLine("#000000", "blackSelect")}
                    />
                    <rect
                      id="whiteSelect"
                      x="45"
                      width="15px"
                      height="15px"
                      fill="#FFF"
                      stroke="#000"
                      onClick={() => updateSafeAreaLine("#FFFFFF", "whiteSelect")}
                    />
                  </svg>
                ) : (
                  ``
                )}
              </div>
            </div>
            <div className={styles.deluxeCard}>
              <div className={styles.cardImg}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="408"
                  height="267"
                  viewBox="0 0 1088 713"
                >
                  <mask id="cardCrop">
                    <rect fill="rgb(255,255,255,0.10)" width="1088px" height="713px" />
                    <rect
                      fill="rgb(255,255,255)"
                      x="37.5"
                      y="37.5"
                      width="1013px"
                      height="638px"
                      rx="16px"
                      ry="16px"
                    />
                  </mask>
                  <rect
                    className={styles.cardOutline}
                    x="37.5"
                    y="37.5"
                    width="1013px"
                    height="638px"
                    rx="16px"
                    ry="16px"
                    fill="transparent"
                  />
                  <image
                    href={cardFrontDeluxe.imgData}
                    x="0"
                    y="0"
                    height="100%"
                    width="100%"
                    mask="url(#cardCrop)"
                  />
                  {textSafeArea ? (
                    <rect
                      className={styles.textSafeArea}
                      x="75px"
                      y="75px"
                      width="938px"
                      height="563px"
                      fill="transparent"
                      clipPath="url(#cardCrop)"
                    />
                  ) : (
                    ``
                  )}
                </svg>
              </div>
              {OrderNavigation(navigateBack, prevLabel, navToCardBack, nextLabel, buttonDisabled)}
            </div>
          </div>
        </section>
      </>
    )
  }

  return (
    <>
      <Notification />
      <section className={styles.sectionContainer}>
        <div className={styles.sectionLeft}>
          <h4 className={styles.sectionTitle}>upload card front</h4>
          {showCardAmounts()}
          <p>
            Image Dimensions: 1088px width x 713px height <br />
            Accepted File Types: PNG | Max File Size: 100MB
          </p>
          <p>
            Note: Please make sure text or logo is within the safe area. The Canvas area outside the
            Card Outline is intended only to allow the full color printing to the edge of the Card
            Outline. To capture the full color, provide a .PNG flat art file. Uploading a layered
            file will result in color shifting at the time of printing.
          </p>
          <div className={styles.downloadSection}>
            <p>Download a gift card design template by selecting an option below.</p>
            <div className={styles.download}>
              <a href={"/deluxe-design-templates/figma-gift-card-design-template.fig"} download>
                <img src={IconFigma} alt="Download a sketch figma design template" />
              </a>
              <a href={"/deluxe-design-templates/sketch-gift-card-design-template.sketch"} download>
                <img src={IconSketch} alt="Download a sketch design template" />
              </a>
              <a href={"/deluxe-design-templates/photoshop-gift-card-design-template.psd"} download>
                <img src={IconPS} alt="Download an Adobe photoshop design template" />
              </a>
              <a
                href={"/deluxe-design-templates/illustrator-gift-card-design-template.ai"}
                download
              >
                <img src={IconAI} alt="Download an Adobe Illustrator design template" />
              </a>
              <a href={"/deluxe-design-templates/gift-card-design-template-blank.png"} download>
                <img src={IconPNG} alt="Download a blank PNG design template" />
              </a>
            </div>
            <div id="safeAreaTemplate">
              <p className={styles.sampleCardParameters} tabIndex={0}>
                View the Canvas, Card Outline, and Safe Area below:
              </p>
              <img src={SafeAreaPreview} alt="Gift Card safe and bleed design template" />
            </div>
          </div>
        </div>

        <div className={styles.sectionRight}>
          <div className={styles.safeAreaControl}>
            {isDragActive ? (
              <p>Drop your file on top of the card ...</p>
            ) : (
              <p>Drag &amp; drop your file onto the card or click it to select</p>
            )}
            <div className={styles.safeAreaColorSelection}>
              <Switch
                checked={textSafeArea}
                onChange={toggleSafeTextArea}
                name="safeAreaToggle"
                data-test="safe-area-toggle"
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <span>show safe area</span>
              {textSafeArea ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60px"
                  height="20px"
                  viewBox="0 0 60 20"
                >
                  <rect
                    id="redSelect"
                    x="5"
                    width="15px"
                    height="15px"
                    fill="#db5559"
                    stroke="#62B4FF"
                    strokeWidth="2px"
                    onClick={() => updateSafeAreaLine("#db5559", "redSelect")}
                  />
                  <rect
                    id="blackSelect"
                    x="25"
                    width="15px"
                    height="15px"
                    fill="#000"
                    onClick={() => updateSafeAreaLine("#000000", "blackSelect")}
                  />
                  <rect
                    id="whiteSelect"
                    x="45"
                    width="15px"
                    height="15px"
                    fill="#FFF"
                    stroke="#000"
                    onClick={() => updateSafeAreaLine("#FFFFFF", "whiteSelect")}
                  />
                </svg>
              ) : (
                ``
              )}
            </div>
          </div>

          <div {...getRootProps()} className={styles.deluxeCard}>
            <input {...getInputProps()} data-test="upload-element" />

            <div className={styles.cardImg}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="408"
                height="267"
                viewBox="0 0 1088 713"
              >
                <mask id="cardCrop">
                  <rect fill="rgb(255,255,255,0.10)" width="1088" height="713" />
                  <rect
                    fill="rgb(255,255,255)"
                    x="37.5"
                    y="37.5"
                    width="1013px"
                    height="638px"
                    rx="16px"
                    ry="16px"
                  />
                </mask>
                <rect
                  className={styles.cardOutline}
                  x="37.5"
                  y="37.5"
                  width="1013px"
                  height="638px"
                  rx="16px"
                  ry="16px"
                  fill="transparent"
                />
                <image
                  href={cardFrontDeluxe.imgData}
                  x="0"
                  y="0"
                  height="100%"
                  width="100%"
                  mask="url(#cardCrop)"
                />

                {textSafeArea ? (
                  <rect
                    className={styles.textSafeArea}
                    x="75px"
                    y="75px"
                    width="938px"
                    height="563px"
                    fill="transparent"
                    clipPath="url(#cardCrop)"
                  />
                ) : (
                  ``
                )}

                {loadingAnimation ? (
                  <rect
                    id="loadingBackground"
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    fill="white"
                    opacity="40%"
                    clipPath="url(#cardCrop)"
                  />
                ) : (
                  ``
                )}

                {loadingAnimation ? (
                  <foreignObject x="45%" y="45%" width="100%" height="100%">
                    <div xmlns="http://www.w3.org/1999/xhtml">
                      <CircularProgress />
                    </div>
                  </foreignObject>
                ) : (
                  ``
                )}
              </svg>
            </div>
          </div>
          {OrderNavigation(navigateBack, prevLabel, navToCardBack, nextLabel, buttonDisabled)}
        </div>
      </section>
    </>
  )
}

export default DeluxeFrontPage
