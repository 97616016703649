import React from "react"
import { toTitleCase } from "../../services/card"
import * as styles from "./order-history.module.scss"

const StatusDisplayMessage = (orderStatus, cardType, trackingNumber) => {
  switch (orderStatus) {
    case "PENDING":
      return orderStatusMessageAndTooltip(
        "Order received. You have 1 hour from submission time to cancel or make changes.",
        "We all know mistakes happen, so just in case, we're giving you 1 hour after you submit the order to cancel.",
        cardType,
      )
    case "ERROR":
      return orderStatusMessageAndTooltip(
        "There was an error processing your order. Please try again. If this continues, please contact support.",
        "There was an error processing your order. Please try again. If this continues, please contact support.",
        cardType,
      )
    case "SUBMITTED":
      return orderStatusMessageAndTooltip("Order Submitted", "Order Submitted", cardType)
    case "RECEIVED":
      return orderStatusMessageAndTooltip(
        "Great news. Our best artist is on your job.",
        "Great news. Our best artist is on your job.",
        cardType,
      )
    case "ART_APPROVED":
      return orderStatusMessageAndTooltip("Artwork approved", "Artwork approved", cardType)
    case "PRINTED":
      return orderStatusMessageAndTooltip(
        "Front of card image printed",
        "Front of card image printed",
        cardType,
      )
    case "ENCODED":
      return orderStatusMessageAndTooltip(
        "Account numbers printed on back of card",
        "Account numbers printed on back of card",
        cardType,
      )
    case "PACKAGED":
      return orderStatusMessageAndTooltip(
        "Card order packaged and ready for shipment",
        "Card order packaged and ready for shipment",
        cardType,
      )
    case "SHIPPED":
      // NOTE: Fetch 'trackingNumber' from graphql api
      return orderStatusMessageAndTooltip(
        `Your Gift Card order has shipped. Tracking number: ${trackingNumber}`,
        "Shipped",
        cardType,
      )
    case "DONE":
      return orderStatusMessageAndTooltip("Order delivered", "Order delivered", cardType)
    case "CANCELLED":
      return orderStatusMessageAndTooltip("Cancelled", "Cancelled", cardType)
    case "RETURNED":
      return orderStatusMessageAndTooltip(
        "Please contact FiServ Gift Card Production Support",
        "Please contact FiServ Gift Card Production Support",
        cardType,
      )
    case "COMPLETE":
      return orderStatusMessageAndTooltip("Complete", "Complete", cardType)
    default:
      // COMPLETE, ERROR, DELAYED and the rest
      return orderStatusMessageAndTooltip(toTitleCase(orderStatus), toTitleCase(orderStatus))
  }
}

const orderStatusMessageAndTooltip = (standardDeluxeMessage, designerMessage, cardType) => {
  if (cardType === "STD" || cardType === "DELUXE") {
    return <span className={styles.dataPoint}>{standardDeluxeMessage}</span>
  } else if (cardType === "DESIGNER") {
    return <span className={styles.dataPoint}>{designerMessage}</span>
  } else {
    return
  }
}

export default StatusDisplayMessage
