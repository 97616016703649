import React, { useRef, useContext, useEffect, useState } from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/privateRoute"
import DashboardPage from "../components/views/dashboard"
import StandardFrontPage from "../components/views/order/standardFront"
import DeluxeFrontPage from "../components/views/order/deluxeFront"
import DesignerFrontPage from "../components/views/order/designerFront"
import StandardBackPage from "../components/views/order/standardBack"
import AccessoriesPage from "../components/views/order/accessories"
import ShippingPage from "../components/views/checkout/shipping"
import PaymentPage from "../components/views/checkout/payments"
import ReviewOrderPage from "../components/views/checkout/reviewOrder"
import OrderSummaryPage from "../components/views/summary"
import ProfilePage from "../components/views/profile"
import ConversionPage from "../components/views/conversion"
import ReportsPage from "../components/views/reports"
import SupportComponent from "../components/views/support"
import OrderHistoryComponent from "../components/orderHistory"
import NotFound from "./404"
import { isBrowser } from "../services/auth"
import { Auth } from "@aws-amplify/auth"
import { navigate } from "gatsby"
import { initConfig } from "../services/amplify"
import InvoicePage from "../components/views/summary/invoice"
import LocationManagementComponent from "../components/locationManagement"
import LocationDetailsPage from "../components/locationDetails"
import { AppStateContext } from "../context/appContext"
import CloverPage from "./clover"

const SESSION_TIMEOUT_MINUTES = 15

Auth.configure(initConfig())

const App = ({ children, location }) => {
  const sessionTimeout = useRef(null)
  const state = useContext(AppStateContext)
  const [showWidget, setShowWidget] = useState(false)

  // null check to prevent creating new timeouts
  if (isBrowser() && sessionTimeout.current === null) {
    const signOutHandler = () => {
      if (window.zE) {
        window.zE("webWidget", "hide")
      }
      Auth.signOut()
      navigate("/logged-out")
    }
    const setSessionTimeout = () => {
      sessionTimeout.current = window.setTimeout(
        signOutHandler,
        SESSION_TIMEOUT_MINUTES * 60 * 1000, // convert to milliseconds
      )
    }
    const resetSessionTimeout = () => {
      window.clearTimeout(sessionTimeout.current)
      setSessionTimeout()
    }

    setSessionTimeout()

    // click and keypress events, reset timeout
    document.body.addEventListener("click", resetSessionTimeout)
    document.body.addEventListener("keypress", resetSessionTimeout)
  }

  useEffect(() => {
    if (state.loggedIn && state.user) {
      setShowWidget(true)
    }
  }, [state])

  return (
    <Layout children={children} location={location}>
      {process.env.GATSBY_ZENDESK_KEY && isBrowser() && state.loggedIn && state.user && (
        <SupportComponent user={state.user} show={showWidget} />
      )}
      <Router basepath="/app">
        <PrivateRoute path="/dashboard" component={DashboardPage} />
        <PrivateRoute path="/order/card-front" component={StandardFrontPage} />
        <PrivateRoute path="/order/card-front-deluxe" component={DeluxeFrontPage} />
        <PrivateRoute path="/order/designer" component={DesignerFrontPage} />
        <PrivateRoute path="/order/card-back" component={StandardBackPage} />
        <PrivateRoute path="/order/accessories" component={AccessoriesPage} />
        <PrivateRoute path="/checkout/shipping" component={ShippingPage} />
        <PrivateRoute path="/checkout/payment" component={PaymentPage} />
        <PrivateRoute path="/checkout/review-order" component={ReviewOrderPage} />
        <PrivateRoute path="/orders" component={OrderHistoryComponent} />
        <PrivateRoute path="/orders/order-summary" component={OrderSummaryPage} />
        <PrivateRoute path="/orders/order-summary/invoice" component={InvoicePage} />
        <PrivateRoute path="/reports" component={ReportsPage} />
        <PrivateRoute path="/locations" component={LocationManagementComponent} />
        <PrivateRoute path="/locations/location-details" component={LocationDetailsPage} />
        <PrivateRoute path="/conversion" component={ConversionPage} />
        <PrivateRoute path="/settings" component={ProfilePage} />
        <PrivateRoute path="/clover" component={CloverPage} />
        <NotFound path="/404/" />
      </Router>
    </Layout>
  )
}

export default App
