import React, { useState, useContext, useEffect } from "react"
import * as styles from "../accessories/accessories.module.scss"
import { css } from "@emotion/react"
import { AppDispatchContext, AppStateContext } from "../../../../context/appContext"
import DoorDecals from "../../../img/marketing/doorDecals"
import CountertopDisplays from "../../../img/marketing/countertopDisplays"
import { carrierItems } from "../../../../constants/marketing"
import { UPDATE_ACCESSORIES } from "../../../../constants/actionTypes"
import { convertCentsToCurrency } from "../../../../utils/helper"
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import { makeStyles } from "@material-ui/styles"
import { navigate } from "gatsby"
import { getCarrierUrl } from "../../../../services/marketing"
import { getPricingByCategory } from "../../../../services/pricing"
import { OrderNavigation } from "../../../orderNavigation"
import {
  ORDER_CATEGORY_STANDARD_CARRIER,
  ORDER_CATEGORY_DELUXE_ACCESSORY,
  ORDER_CATEGORY_STANDARD_ACCESSORY,
  ORDER_CATEGORY_DELUXE_CARRIER,
} from "../../../../constants/orderCategory"
import { isDeluxe } from "../../../../services/card"

const countertopDisplayType = "MNF2"
const doorDecalType = "MNRH"

const AccessoriesPage = () => {
  const dispatch = useContext(AppDispatchContext)
  const state = useContext(AppStateContext)
  const stateMC = state.order.accessories
  const [accessories, setAccessories] = useState({
    selectedCarrierType: "JC-SIL",
    selectedCarrierAmount: 0,
    countertopDisplays: stateMC.countertopDisplays,
    doorDecals: stateMC.doorDecals,
    summary: [...stateMC.summary],
  })
  const [editCarrier, setEditCarrier] = useState(false)
  const [selectedCarrierAmount, setSelectedCarrierAmount] = useState(0)
  const useStyles = makeStyles(() => ({
    quantitySelect: {
      width: 200,
    },
  }))

  const carrierPricing = getPricingByCategory("carrier", undefined, state.authProvider)
  carrierPricing.sort((a, b) => {
    if (a.quantity === b.quantity) {
      return 0
    }
    return a.quantity < b.quantity ? -1 : 1
  })

  const carrierQuantities = carrierPricing.reduce(
    (result, price) => {
      const { quantity, total_price } = price
      return {
        ...result,
        [quantity]: {
          label: quantity + " - $" + (total_price / 100).toFixed(2),
          value: quantity,
          cost: total_price,
        },
      }
    },
    {
      // initial value
      0: {
        label: "None",
        value: 0,
        cost: 0,
      },
    },
  )

  const collateralPricing = getPricingByCategory("collateral", undefined, state.authProvider)
  const collateralQuantitiesMap = collateralPricing.reduce((result, price) => {
    const minQuantity = price.min_quantity || 1
    const maxQuantity = price.max_quantity || 100
    const stepSize = price.step_size || 1

    const opts = {
      0: {
        label: "None",
        value: 0,
        cost: 0,
      },
    }

    for (let i = minQuantity; i <= maxQuantity; i = i + stepSize) {
      opts[i] = {
        label: i + " - $" + ((i * price.unit_price) / 100).toFixed(2),
        value: i,
        cost: i * price.unit_price,
      }
    }

    result[price.sub_category] = opts

    return result
  }, {})

  const nextLabel = "Shipping"
  const prevLabel = "Back: Card Back"

  const handleSubmit = () => {
    dispatch({
      type: UPDATE_ACCESSORIES,
      payload: accessories,
    })

    navigate("/app/checkout/shipping")
  }

  const navigateBack = () => {
    navigate("/app/order/card-back")
  }

  // Warn users before: reload, close window, close tab
  window.addEventListener("beforeunload", function (e) {
    const pathname = window.location.pathname
    //Paths for orders: card-front, card-back accessories shipping review-order payment
    if (
      pathname === "/app/order/card-front" ||
      pathname === "/app/order/card-front-deluxe" ||
      pathname === "/app/order/designer" ||
      pathname === "/app/order/card-back" ||
      pathname === "/app/order/accessories" ||
      pathname === "/app/checkout/shipping" ||
      pathname === "/app/checkout/review-order" ||
      pathname === "/app/checkout/payment" ||
      pathname === "/app/checkout/payment/" ||
      pathname === "/app/order/designer"
    ) {
      e.returnValue = ""
    }
  })

  const handleSummaryUpdates = event => {
    const { value, name } = event.target
    let index = 0
    let item = {}
    let cost = 0
    switch (name) {
      case "countertopDisplays":
        index = accessories.summary.findIndex(obj => obj.type === countertopDisplayType)
        cost = collateralQuantitiesMap["counter_top_display"][value].cost
        item = {
          orderCategory: isDeluxe(state.order)
            ? ORDER_CATEGORY_DELUXE_ACCESSORY
            : ORDER_CATEGORY_STANDARD_ACCESSORY, // Promotional Items
          quantity: value,
          type: countertopDisplayType,
          cost,
          description: "Countertop Display",
        }
        if (value === 0) {
          accessories.summary.splice(index, 1)
        } else if (accessories.summary.some(obj => obj.type === countertopDisplayType)) {
          accessories.summary.splice(index, 1)
          accessories.summary.push(item)
        } else {
          accessories.summary.push(item)
        }
        setAccessories({
          ...accessories,
          [name]: value,
        })
        break
      case "doorDecals":
        index = accessories.summary.findIndex(obj => obj.type === doorDecalType)
        cost = collateralQuantitiesMap["gift_card_door_decal"][value].cost
        item = {
          orderCategory: isDeluxe(state.order)
            ? ORDER_CATEGORY_DELUXE_ACCESSORY
            : ORDER_CATEGORY_STANDARD_ACCESSORY, // Promotional Items
          quantity: value,
          type: doorDecalType,
          cost,
          description: "Door Decal",
        }
        if (value === 0) {
          accessories.summary.splice(index, 1)
        } else if (accessories.summary.some(obj => obj.type === doorDecalType)) {
          accessories.summary.splice(index, 1)
          accessories.summary.push(item)
        } else {
          accessories.summary.push(item)
        }
        setAccessories({
          ...accessories,
          [name]: value,
        })
        break
      default:
      // Do nothing
    }
  }

  const selectCarrierType = event => {
    const { value, name } = event.target
    setAccessories({
      ...accessories,
      [name]: value,
    })
    setSelectedCarrierAmount(0)
  }

  const addCarrier = () => {
    const type = accessories.selectedCarrierType
    const quantity = selectedCarrierAmount
    const cost = carrierQuantities[quantity].cost
    const item = {
      orderCategory: isDeluxe(state.order)
        ? ORDER_CATEGORY_DELUXE_CARRIER
        : ORDER_CATEGORY_STANDARD_CARRIER, // Carrier or Custom Cards
      isCardCarrier: "CARRIERS",
      quantity: quantity,
      type: `${type}`,
      cost,
      description: type,
    }
    const index = accessories.summary.findIndex(obj => obj.type === type)
    if (quantity === 0 && index !== -1) {
      accessories.summary.splice(index, 1)
    } else if (accessories.summary.some(obj => obj.type === type) && quantity !== 0) {
      accessories.summary.splice(index, 1)
      accessories.summary.push(item)
    } else if (quantity !== 0) {
      accessories.summary.push(item)
    }
    setAccessories({
      ...accessories,
      selectedCarrierType: type,
      selectedCarrierAmount: quantity,
    })
  }

  const handleRemove = type => {
    if (type === countertopDisplayType) {
      accessories.countertopDisplays = 0
    } else if (type === doorDecalType) {
      accessories.doorDecals = 0
    } else if (carrierItems.find(item => item.value === type)) {
      setSelectedCarrierAmount(0)
    }

    const newSummary = accessories.summary.filter(item => item.type !== type)

    setAccessories({
      ...accessories,
      summary: [...newSummary],
    })
  }

  const getSummaryTotal = () => {
    const total = accessories.summary.reduce((cost, item) => cost + item.cost, 0)
    return convertCentsToCurrency(total)
  }

  useEffect(() => {
    if (accessories.summary.some(obj => obj.type === accessories.selectedCarrierType)) {
      setEditCarrier(true)
      const index = accessories.summary.findIndex(
        obj => obj.type === accessories.selectedCarrierType,
      )
      setSelectedCarrierAmount(accessories.summary[index].quantity)
    } else {
      setEditCarrier(false)
    }
  }, [accessories])

  const classes = useStyles()

  const summaryTotal = css(`
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    border-top: 1px solid gray;
    padding: 12px 5px 0 0;
    margin-right:50px
`)

  return (
    <>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionLeft}>
          <h2 className={styles.sectionTitle}>Order Accessories</h2>
          <div className={styles.shop}>
            <Card className={`${styles.shopCard} elevatedCard`}>
              <CardContent>
                <h5>card carriers</h5>
                <FormControl className={classes.quantitySelect}>
                  <InputLabel>Select card carrier</InputLabel>
                  <Select
                    value={accessories.selectedCarrierType}
                    name="selectedCarrierType"
                    id="selectedCarrierType"
                    onChange={event => selectCarrierType(event)}
                  >
                    {carrierItems.map(key => {
                      return (
                        <MenuItem key={key.label} id={key.value} value={key.value}>
                          {key.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>

                <div id="carrier-image-container">
                  <img
                    id={accessories.selectedCarrierType}
                    alt={accessories.selectedCarrierType}
                    src={getCarrierUrl(accessories.selectedCarrierType)}
                  />
                </div>

                <FormControl className={classes.quantitySelect}>
                  <InputLabel>Select Quantity</InputLabel>
                  <Select
                    id="selectedCarrierAmount"
                    name={`selectedCarrierAmount`}
                    value={selectedCarrierAmount}
                    onChange={event => setSelectedCarrierAmount(event.target.value)}
                  >
                    {Object.values(carrierQuantities).map(key => {
                      return (
                        <MenuItem key={key.label} id={key.value} value={key.value}>
                          {key.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </CardContent>
              <CardActions>
                <Button
                  color="secondary"
                  className="buttonSecondary"
                  id={`addCarrier`}
                  name={`addCarrier`}
                  value={`Add Carrier`}
                  variant="outlined"
                  onClick={addCarrier}
                >
                  {editCarrier ? "Edit Carrier" : "Add Carrier"}
                </Button>
              </CardActions>
            </Card>
            <Card className={`${styles.shopCard} elevatedCard`}>
              <CardContent>
                <h5>Countertop Display</h5>
                <CountertopDisplays />
                <FormControl className={classes.quantitySelect}>
                  <InputLabel>Select Quantity</InputLabel>
                  <Select
                    id={"countertopDisplays"}
                    name={"countertopDisplays"}
                    value={accessories.countertopDisplays}
                    onChange={event => handleSummaryUpdates(event)}
                  >
                    {Object.values(collateralQuantitiesMap["counter_top_display"]).map(key => {
                      return (
                        <MenuItem key={key.label} id={key.value} value={key.value}>
                          {key.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
            <Card className={`${styles.shopCard} elevatedCard`}>
              <CardContent>
                <h5>Door Decal</h5>
                <DoorDecals />
                <FormControl className={classes.quantitySelect}>
                  <InputLabel>Select Quantity</InputLabel>
                  <Select
                    id={`doorDecals`}
                    name={`doorDecals`}
                    value={accessories.doorDecals}
                    onChange={event => handleSummaryUpdates(event)}
                  >
                    {Object.values(collateralQuantitiesMap["gift_card_door_decal"]).map(key => {
                      return (
                        <MenuItem key={key.label} id={key.value} value={key.value}>
                          {key.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className={styles.sectionRight}>
          <div className={styles.summaryContainer}>
            <Card className={`${styles.shopSummary} elevatedCard`}>
              <CardContent className={styles.cardContent}>
                <h2 className={styles.sectionTitle}>Summary</h2>
                <div id="summary-container">
                  {accessories.summary.map(key => {
                    return (
                      <div key={key.type} className={styles.summaryItem} id={key.quantity}>
                        <div className={styles.summaryItemLeft}>
                          {key.description} ({key.quantity})
                        </div>
                        <div className={styles.summaryItemRight} id={key.quantity}>
                          ${convertCentsToCurrency(key.cost)}
                        </div>
                        <Tooltip title="Delete" enterDelay={500}>
                          <IconButton
                            id={`delete-${key.type}`}
                            key={`delete-${key.type}`}
                            aria-label="delete"
                            className={styles.summaryItemRemove}
                            onClick={() => handleRemove(key.type)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )
                  })}
                </div>
                {accessories.summary.length < 1 ? (
                  <p>No accessories selected yet ...</p>
                ) : (
                  <div css={summaryTotal}>
                    <div>Total</div>
                    <div className={styles.summaryTotal}>${getSummaryTotal()}</div>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
          {OrderNavigation(navigateBack, prevLabel, handleSubmit, nextLabel)}
        </div>
      </div>
    </>
  )
}
export default AccessoriesPage
