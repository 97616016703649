import { getSessionStateForReOrder } from "../context/appContext"
import { generateOrderId } from "../services/order-id"
import {
  getCardType,
  getTotal,
  getTaxValue,
  isDesigner,
  getSubTotalCost,
  isDeluxe,
} from "../services/card"
import { getItemByQuantity, getItemBySku } from "../services/pricing"
import { AUTH_PROVIDER_CLOVER } from "../constants/authProvider"
import { GC_SOURCE_CLOVER, GC_SOURCE_PBC } from "../constants/gcSource"
import { v4 as uuidv4 } from "uuid"
import { Storage } from "aws-amplify"
import {
  ORDER_CATEGORY_DELUXE_CARD,
  ORDER_CATEGORY_DELUXE_CARRIER,
  ORDER_CATEGORY_STANDARD_CARRIER,
  ORDER_CATEGORY_DESIGNER,
  ORDER_CATEGORY_STANDARD_CARD,
} from "../constants/orderCategory"

export const getSubmitOrderJson = async (
  authProvider,
  user,
  order,
  profileAddress,
  paymentDetails,
  designerDetails,
) => {
  const cognitoSub = user.sub
  const gatewayMID = user.gatewayMID
  const closedLoopMID = user.closedLoopMID
  const username = user.username
  const merchantName = user.name
  const isDesignerOrder = order?.cards?.cardType.toLowerCase() === "designer"
  const amountOfCards = isDesignerOrder ? 1 : order.cards.amountOfCards
  const current_state = JSON.parse(getSessionStateForReOrder())
  const itemType = order.cards.cardType.toUpperCase()
  const cardType = getCardType(itemType)
  const orderId = await generateOrderId(cardType)
  const itemPricingByQuantity = getItemByQuantity(
    "card",
    order.cards.cardType.toLowerCase(),
    authProvider,
    amountOfCards,
  )

  const taxAmount = await getTaxValue(order, profileAddress)
  const totalAmount = await getTotal(order, profileAddress)
  const subtotalAmount = getSubTotalCost(order)

  const shipToAddress = isDesignerOrder
    ? {
        shipAddress1: "",
        shipCity: "",
        shipState: "",
        shipZipCode: "",
      }
    : order.shipping.shipToAddress

  let items = [
    {
      orderCategory: isDesignerOrder
        ? ORDER_CATEGORY_DESIGNER
        : isDeluxe(order)
        ? ORDER_CATEGORY_DELUXE_CARD
        : ORDER_CATEGORY_STANDARD_CARD,
      isCardCarrier: "CARDS",
      type: cardType.toUpperCase(),
      quantity: amountOfCards,
      cost:
        itemPricingByQuantity && itemPricingByQuantity.length > 0
          ? parseInt(itemPricingByQuantity[0]["total_price"])
          : 0,
      description:
        itemPricingByQuantity && itemPricingByQuantity.length > 0
          ? itemPricingByQuantity[0]["label"]
          : "",
    },
  ]

  if (!isDesignerOrder) {
    const card_front_url = await saveImage(order.cards.cardFront.imgData, "front")
    const card_back_url = await saveImage(order.cards.cardBack.imgData, "back")
    items[0].artWorkText = card_front_url
    items[0].artWorkBackText = card_back_url
  } else if (isDesignerOrder) {
    items[0].contactDetails =
      Object.keys(designerDetails).length > 0 && designerDetails["contactDetails"]
        ? designerDetails["contactDetails"]
        : order.cards.contactDetails
    items[0].designerAssetsUrl =
      Object.keys(designerDetails).length > 0 && designerDetails["designerAssetsUrl"]
        ? designerDetails["designerAssetsUrl"]
        : order.cards.designerAssetsUrl
    items[0].notes =
      Object.keys(designerDetails).length > 0 && designerDetails["notesForDesigner"]
        ? designerDetails["notesForDesigner"]
        : order.cards.notes
  }
  if (isDesignerOrder) {
    //Update the State with Pricing
    if (items[0].cost !== 0 && current_state.order.cards) {
      current_state.order.cards.amountOfCards = amountOfCards
      current_state.order.cards.cost = items[0].cost
    }
  }

  let paymentNonce = ""
  if (paymentDetails && paymentDetails.nonce) {
    paymentNonce = paymentDetails.nonce
    delete paymentDetails.nonce
  } else if (order.payment && order.payment.nonce) {
    paymentNonce = order.payment.nonce
  }

  const payment = {
    paymentMethod: user.paymentMethod,
    paymentStatus: "START",
    subtotalAmount: subtotalAmount ? (subtotalAmount * 100) | 0 : 0,
    totalAmount: totalAmount ? totalAmount : 0,
    taxAmount: taxAmount ? (taxAmount * 100) | 0 : 0,
    shippingAmount: isDesigner(order) ? 0 : order?.shipping?.cost ? order?.shipping?.cost : 0,
    paymentDetails: {
      ...paymentDetails,
    },
    promoDetails: {},
  }
  if (order.payment && order.payment.promoDetails && order.payment.promoDetails.discount > 0) {
    payment.promoDetails = {
      ...order.payment.promoDetails,
    }
  }

  order.accessories.summary.forEach(item => {
    const temp = {
      ...item,
    }
    let itemPricing = {}
    if (
      [ORDER_CATEGORY_STANDARD_CARRIER, ORDER_CATEGORY_DELUXE_CARRIER].includes(item.orderCategory)
    ) {
      itemPricing = getItemByQuantity(
        "carrier",
        order.cards.cardType.toLowerCase(),
        authProvider,
        item.quantity,
      )
    } else {
      itemPricing = getItemBySku(authProvider, "collateral", item.type)
    }

    temp["description"] = itemPricing && itemPricing.length > 0 ? itemPricing[0]["label"] : ""
    items.push(temp)
  })
  const currentState = isDesignerOrder ? JSON.stringify(current_state) : getSessionStateForReOrder()
  const shippingMethod = isDesignerOrder ? "GROUND" : order.shipping.requestedShipMethod
  const inputOrder = {
    PK: gatewayMID,
    username,
    cognitoSub,
    gatewayMID,
    closedLoopMID,
    authProvider,
    orderId,
    gcSource: authProvider === AUTH_PROVIDER_CLOVER ? GC_SOURCE_CLOVER : GC_SOURCE_PBC,
    email: user.email,
    itemType,
    requestedShipMethod: shippingMethod,
    shipToAddress,
    items,
    payment,
    nonce: paymentNonce,
    status: "PENDING",
    pending: 1,
    state: currentState,
    itemsJson: JSON.stringify(items),
    paymentJson: JSON.stringify(payment),
    shipToAddressJson: JSON.stringify(shipToAddress),
    itemsCount: items.length,
    merchantName,
  }

  if (
    order.payment &&
    order.payment.promoDetails &&
    order.payment.promoDetails.discount > 0 &&
    order.payment.promoDetails.promoCode
  ) {
    inputOrder["couponCode"] = order.payment.promoDetails.promoCode
    inputOrder["couponValidationParams"] = order.payment.promoDetails?.params
      ? order.payment.promoDetails.params
      : ""
  }
  if (isDeluxe(order) && "designOrderId" in order) {
    inputOrder["designOrderId"] = order.designOrderId
  }
  if ("originalOrderId" in order) {
    inputOrder["originalOrderId"] = order.originalOrderId
  }
  console.log("Input Order", inputOrder)
  return inputOrder
}

const saveImage = async (imgData, type) => {
  if (imgData.startsWith("https://")) {
    return imgData
  }

  const validTypes = ["front", "back"]
  if (!type || validTypes.indexOf(type) === -1) {
    type = "front"
  }

  const fileId = uuidv4()
  const filename = `${fileId}-${type}.png`
  const data = dataUrlToFile(imgData, "card-front")
  await Storage.put(filename, data, {
    level: "protected",
    contentType: "image/png",
    acl: "public-read", // need this so we can download directly from S3
  })

  // get url from Storage...better way?
  const signedUrl = await Storage.get(filename, {
    level: "protected",
  })
  const url = new URL(signedUrl)
  return `https://${url.host}${url.pathname}` // pathname has leading slash
}

/**
 * Convert base-64 encoded data to a File
 * @param data
 * @param name
 * @return {File}
 */
const dataUrlToFile = (data, name) => {
  const parts = data.split(",")
  const dataStr = window.atob(parts[1])
  const byteArray = new Uint8Array(dataStr.length)

  for (let i = 0; i < byteArray.length; i++) {
    byteArray[i] = dataStr.charCodeAt(i)
  }

  return new File([byteArray], name, { type: "image/png " })
}
