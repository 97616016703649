import React, { useContext, useEffect, useState } from "react"
import { AppStateContext } from "../../context/appContext"
import * as styles from "../locationDetails/location-details.module.scss"
import { Button, Card, CardContent } from "@material-ui/core"
import { navigate } from "gatsby"

const LocationDetailsPage = () => {
  const state = useContext(AppStateContext)
  const [currentLocation, setCurrentLocation] = useState(null)

  useEffect(() => {
    const loadLocation = locationId => {
      try {
        const locationDetails = state.locationManagement.find(
          element => element.locationID === locationId,
        )
        if (Object.keys(locationDetails).length !== 0) {
          setCurrentLocation(locationDetails)
        }
      } catch (err) {
        console.warn("Failed to load location " + locationId, err)
        navigate("/app/locations/")
      }
    }

    if (state && document.location.hash && document.location.hash.length > 2) {
      loadLocation(document.location.hash.substr(1))
    } else {
      navigate("/app/locations/")
    }
  }, [state])

  const viewLocations = () => {
    navigate("/app/locations")
  }

  const formatAddress = () => {
    if (!currentLocation || !currentLocation.address) {
      return ""
    }
    const addressObj = currentLocation.address
    const address = [addressObj.addressLine1 || ""]
    if (addressObj.addressLine2) {
      address.push(addressObj.addressLine2)
    }
    if (addressObj.addressLine3) {
      address.push(addressObj.addressLine3)
    }
    address.push(`${addressObj.city || ""}, ${addressObj.state || ""} ${addressObj.zipCode || ""}`)
    return address.map((line, idx) => {
      return <div key={idx}>{line}</div>
    })
  }

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.sectionLeft}>
        <div>
          <h2 className={styles.sectionTitle}>location details</h2>
        </div>
        <Card>
          <CardContent>
            <div className={styles.locationContent}>
              <div className={styles.left}>
                <div>
                  <strong>Merchant Name: </strong> {currentLocation ? currentLocation.name : ""}
                </div>
                <div>
                  <strong>Merchant ID: </strong> {currentLocation ? currentLocation.merchantId : ""}
                </div>
              </div>

              <div className={styles.right}>
                <div>
                  <strong>Location ID: </strong> {currentLocation ? currentLocation.locationID : ""}
                </div>
                <div>
                  <strong>Program Type:</strong>{" "}
                  {currentLocation ? currentLocation.programType : ""}
                </div>
                <div>
                  <strong>Address: </strong> {formatAddress()}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className={styles.sectionRight}>
        <div>
          <Button
            variant="contained"
            color="primary"
            className="buttonPrimary"
            id="back-button"
            onClick={viewLocations}
          >
            back
          </Button>
        </div>
      </div>
    </div>
  )
}

export default LocationDetailsPage
