import React, { useState, useContext, useEffect, useRef } from "react"
import { navigate } from "gatsby"
import * as styles from "../standardBack/card-back.module.scss"
import { AppDispatchContext, AppStateContext } from "../../../../context/appContext"
import { UPDATE_CARD_BACK } from "../../../../constants/actionTypes"
import { AUTH_PROVIDER_CLOVER } from "../../../../constants/authProvider"
import { Button, ButtonGroup, FormControl, TextField, Grid } from "@material-ui/core"
import { css } from "@emotion/react"
import { API, graphqlOperation } from "aws-amplify"
import { getProfile } from "../../../../graphql/queries"
import domtoimage from "../../../../services/dom-to-image"
import { blobToBase64 } from "../../../../services/card"
import { PHYSICAL_CARD_TERMS } from "../../../../constants/terms"
import { OrderNavigation } from "../../../orderNavigation"

export default function StandardBackPage() {
  const dispatch = useContext(AppDispatchContext)
  const state = useContext(AppStateContext)
  const cardBackSession = state.order.cards.cardBack
  const hasTermsProperty = Object.prototype.hasOwnProperty.call(cardBackSession, "terms")
  const [cardBack, setCardBack] = useState({
    line1: cardBackSession.line1,
    line2: cardBackSession.line2,
    line3: cardBackSession.line3,
    terms: hasTermsProperty ? cardBackSession.terms : "Loading...",
    imgData: cardBackSession.imgData,
  })
  const nextLabel = state.authProvider === AUTH_PROVIDER_CLOVER ? "Shipping" : "Accessories"
  const prevLabel =
    state.order.cards.cardType === "standard" ? "Back: Card Front" : "Back: Deluxe Order"

  // Warn users before: reload, close window, close tab
  window.addEventListener("beforeunload", function (e) {
    const pathname = window.location.pathname
    //Paths for orders: card-front, card-back accessories shipping review-order payment
    if (
      pathname === "/app/order/card-front" ||
      pathname === "/app/order/card-front-deluxe" ||
      pathname === "/app/order/designer" ||
      pathname === "/app/order/card-back" ||
      pathname === "/app/order/accessories" ||
      pathname === "/app/checkout/shipping" ||
      pathname === "/app/checkout/review-order" ||
      pathname === "/app/checkout/payment" ||
      pathname === "/app/checkout/payment/" ||
      pathname === "/app/order/designer"
    ) {
      e.returnValue = ""
    }
  })

  useEffect(() => {
    const loadTerms = async () => {
      try {
        const params = { PK: state.user.gatewayMID }
        const {
          data: {
            getProfile: { physicalCardTerms },
          },
        } = await API.graphql(graphqlOperation(getProfile, params))
        if (physicalCardTerms !== null) {
          return physicalCardTerms
        }
      } catch (err) {}
      return PHYSICAL_CARD_TERMS
    }
    if (!hasTermsProperty) {
      loadTerms().then(data =>
        setCardBack(prevState => {
          return {
            ...prevState,
            terms: {
              ...prevState.terms,
              text: data,
            },
          }
        }),
      )
    }
  }, [state, hasTermsProperty])

  const lineTwoRef = useRef()
  const lineThreeRef = useRef()

  const overflowCheck = e => {
    const textDimension = e.getBoundingClientRect()
    let underflowMarker = 345 - textDimension.width

    if (textDimension.width > 345 && cardBack.line3.fontSize > 8) {
      decrementLineThreeFontSize()
    } else if (underflowMarker > 12 && cardBack.line3.fontSize < 14) {
      incrementLineThreeFontSize()
    } else if (textDimension.width > 70 && underflowMarker > 270 && cardBack.line3.fontSize > 13) {
      decrementLineThreeFontSize()
    }
  }

  const overflowCheckTwo = e => {
    const textDimension = e.getBoundingClientRect()
    let underflowMarker = 345 - textDimension.width

    if (textDimension.width > 345 && cardBack.line2.fontSize > 8) {
      decrementLineTwoFontSize()
    } else if (underflowMarker > 12 && cardBack.line2.fontSize < 16) {
      incrementLineTwoFontSize()
    } else if (textDimension.width > 170 && underflowMarker > 170 && cardBack.line2.fontSize > 15) {
      //This clause is used to handle a copy paste with max characters, size print ends up too large
      setCardBack(prevState => {
        return {
          ...prevState,
          line2: {
            ...prevState.line2,
            fontSize: cardBack.line2.fontSize - 0.0,
            sizePrint: cardBack.line2.sizePrint - 5.0,
          },
        }
      })
    }
  }

  //Font sizes can increase and decrease from (+) (-) buttons or dynamically as user types
  const decrementLineOneFontSize = () => {
    setCardBack(prevState => {
      return {
        ...prevState,
        line1: {
          ...prevState.line1,
          fontSize: cardBack.line1.fontSize - 1.0,
          sizePrint: cardBack.line1.sizePrint - 2.0,
        },
      }
    })
  }

  const incrementLineOneFontSize = () => {
    setCardBack(prevState => {
      return {
        ...prevState,
        line1: {
          ...prevState.line1,
          fontSize: cardBack.line1.fontSize + 1.0,
          sizePrint: cardBack.line1.sizePrint + 2.0,
        },
      }
    })
  }

  const decrementLineTwoFontSize = () => {
    setCardBack(prevState => {
      return {
        ...prevState,
        line2: {
          ...prevState.line2,
          fontSize: cardBack.line2.fontSize - 1.0,
          sizePrint: cardBack.line2.sizePrint - 5.0,
        },
      }
    })
  }

  const incrementLineTwoFontSize = () => {
    setCardBack(prevState => {
      return {
        ...prevState,
        line2: {
          ...prevState.line2,
          fontSize: cardBack.line2.fontSize + 1.0,
          sizePrint: cardBack.line2.sizePrint + 5.0,
        },
      }
    })
  }

  const decrementLineThreeFontSize = () => {
    setCardBack(prevState => {
      return {
        ...prevState,
        line3: {
          ...prevState.line3,
          fontSize: cardBack.line3.fontSize - 1.0,
          sizePrint: cardBack.line3.sizePrint - 1.0,
        },
      }
    })
  }

  const incrementLineThreeFontSize = () => {
    setCardBack(prevState => {
      return {
        ...prevState,
        line3: {
          ...prevState.line3,
          fontSize: cardBack.line3.fontSize + 1.0,
          sizePrint: cardBack.line3.sizePrint + 1.0,
        },
      }
    })
  }

  const decrementTermsFontSize = () => {
    setCardBack(prevState => {
      return {
        ...prevState,
        terms: {
          ...prevState.terms,
          fontSize: cardBack.terms.fontSize - 1.0,
          sizePrint: cardBack.terms.sizePrint - 3.0,
        },
      }
    })
  }

  const incrementTermsFontSize = () => {
    setCardBack(prevState => {
      return {
        ...prevState,
        terms: {
          ...prevState.terms,
          fontSize: cardBack.terms.fontSize + 1.0,
          sizePrint: cardBack.terms.sizePrint + 3.0,
        },
      }
    })
  }

  const resetTerms = () => {
    let terms = cardBackSession.terms.text
    setCardBack(prevState => {
      return {
        ...prevState,
        terms: {
          ...prevState.terms,
          text: terms,
        },
      }
    })
  }

  const captureCard = () => {
    domtoimage
      .toBlob(document.getElementById("svg"))
      .then(function (blob) {
        // convert blob to base64 so we can persist in window.sessionStorage
        return blobToBase64(blob)
      })
      .then(function (data) {
        setCardBack(prevState => {
          return { ...prevState, imgData: data }
        })
        dispatch({
          type: UPDATE_CARD_BACK,
          payload: {
            line1: cardBack.line1,
            line2: cardBack.line2,
            line3: cardBack.line3,
            terms: cardBack.terms,
            imgData: data,
          },
        })
      })
      .then(function () {})
      .then(function () {
        if (state.authProvider === AUTH_PROVIDER_CLOVER) {
          //Clover users cannot purchase accessories
          navigate("/app/checkout/shipping")
        } else {
          navigate("../accessories/")
        }
      })
      .catch(function (error) {
        console.error("oops, something went wrong with blob generation for card back", error)
      })
  }

  const navigateBack = () => {
    if (state.order.cards.cardType === "deluxe") {
      navigate("/app/order/card-front-deluxe")
    } else {
      navigate("/app/order/card-front")
    }
  }

  const textFieldControls = css`
    width: 100%;
    display: flex;
    flex-direction: row !important;

    .MuiTextField-root {
      width: 90%;
      height: 30px;
      margin-bottom: 29px;
      .MuiInputBase-root {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .MuiButtonGroup-root {
      display: flex;
      flex-direction: column;
      width: 10%;
      height: 3.55rem;

      button {
        height: 29px;
        border-color: #999999;
        border-radius: 0;
        outline: 0;
        border-left: 0;
        color: #000;
      }

      .plus {
        background-color: white;
        margin-left: 0;
        border-top-right-radius: 4px;
        border-bottom: 0;
      }

      .minus {
        background-color: white;
        margin-left: 0;
        border-bottom-right-radius: 4px;
      }
    }
  `

  return (
    <>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionLeft}>
          <h4 className={styles.sectionTitle} data-test="card-back-page-title">
            design card back
          </h4>
          <div className={styles.formContainer}>
            <div className={`sectionFull`}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl variant="filled" className={styles.formFull} css={textFieldControls}>
                    <TextField
                      id="line1"
                      variant="filled"
                      name="line_1"
                      label="Line 1"
                      defaultValue={cardBack.line1.text}
                      inputProps={{ maxLength: 26 }}
                      aria-describedby="my-helper-text"
                      onChange={e => {
                        const val = e.target.value
                        setCardBack(prevState => {
                          return {
                            ...prevState,
                            line1: {
                              ...prevState.line1,
                              text: val,
                            },
                          }
                        })
                      }}
                    />
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      <Button
                        id="line1_plus"
                        className="plus"
                        onClick={() => {
                          incrementLineOneFontSize()
                        }}
                      >
                        +
                      </Button>
                      <Button
                        id="line1_minus"
                        className="minus"
                        onClick={() => {
                          decrementLineOneFontSize()
                        }}
                      >
                        -
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="filled" className={styles.form} css={textFieldControls}>
                    <TextField
                      id="line2"
                      variant="filled"
                      name="line_2"
                      label="Line 2"
                      defaultValue={cardBack.line2.text}
                      aria-describedby="my-helper-text"
                      inputProps={{ maxLength: 42 }}
                      onInput={e => overflowCheckTwo(lineTwoRef.current)}
                      onChange={e => {
                        const val = e.target.value
                        setCardBack(prevState => {
                          return {
                            ...prevState,
                            line2: {
                              ...prevState.line2,
                              text: val,
                            },
                          }
                        })
                      }}
                    />
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      <Button
                        id="line2_plus"
                        className="plus"
                        onClick={() => {
                          incrementLineTwoFontSize()
                        }}
                      >
                        +
                      </Button>
                      <Button
                        id="line2_minus"
                        className="minus"
                        onClick={() => {
                          decrementLineTwoFontSize()
                        }}
                      >
                        -
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="filled" className={styles.form} css={textFieldControls}>
                    <TextField
                      id="line3"
                      variant="filled"
                      name="line_3"
                      label="Line 3"
                      defaultValue={cardBack.line3.text}
                      aria-describedby="my-helper-text"
                      inputProps={{ maxLength: 50 }}
                      onInput={e => overflowCheck(lineThreeRef.current)}
                      onChange={e => {
                        const val = e.target.value
                        setCardBack(prevState => {
                          return {
                            ...prevState,
                            line3: {
                              ...prevState.line3,
                              text: val,
                            },
                          }
                        })
                      }}
                    />
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      <Button
                        id="line3_plus"
                        className="plus"
                        onClick={() => {
                          incrementLineThreeFontSize()
                        }}
                      >
                        +
                      </Button>
                      <Button
                        id="line3_minus"
                        className="minus"
                        onClick={() => {
                          decrementLineThreeFontSize()
                        }}
                      >
                        -
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    variant="filled"
                    className={styles.formLastChild}
                    css={textFieldControls}
                  >
                    <TextField
                      id="terms"
                      variant="filled"
                      name="terms"
                      label="Terms &amp; Conditions"
                      value={cardBack.terms.text}
                      aria-describedby="my-helper-text"
                      multiline
                      inputProps={{ maxLength: 245 }}
                      onChange={e => {
                        const val = e.target.value
                        setCardBack(prevState => {
                          return {
                            ...prevState,
                            terms: {
                              ...prevState.terms,
                              text: val,
                            },
                          }
                        })
                      }}
                    />
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      <Button
                        id="terms_plus"
                        className="plus"
                        onClick={() => {
                          incrementTermsFontSize()
                        }}
                      >
                        +
                      </Button>
                      <Button
                        id="terms_minus"
                        className="minus"
                        onClick={() => {
                          decrementTermsFontSize()
                        }}
                      >
                        -
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                  <p className={styles.resetTerms} onClick={e => resetTerms()}>
                    Reset Terms and Conditions
                  </p>
                  {state.authProvider === AUTH_PROVIDER_CLOVER ? (
                    <p>
                      <span className={styles.tipSpan}>Tip: </span> You can review and update both
                      your default Physical and Digital terms and conditions on the Settings menu.
                      Digital terms and condition is sent every time you sell your gift card from
                      your Clover Device.
                    </p>
                  ) : (
                    <p>
                      <span className={styles.tipSpan}>Tip: </span> You can update your default
                      terms and conditions under the Settings tab.
                    </p>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className={styles.sectionRight}>
          <div className={styles.cardPreviewContainer}>
            <h2 className={styles.sectionTitle}>preview</h2>

            <div className={styles.imagePreviewContainer} id="card-back-preview-container">
              <div id="svgdigital">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 380 238`}>
                  <rect x="0" y="0" width="380" height="238" fill="#ffffff" rx="12" ry="12" />
                  <rect x="0" y="14" width="380" height="35" fill="#000000" />
                  <rect x="215" y="60" width="101" height="26" fill="#D83289" rx="3" ry="3" />
                  <text
                    x="30%"
                    y="33%"
                    fill="#000000"
                    fontSize="14"
                    dominantBaseline="middle"
                    textAnchor="middle"
                  >
                    XXXX XXXX XXXX XXXX
                  </text>
                  <text
                    x="70%"
                    y="33%"
                    fill="#FFFFFF"
                    fontSize="14"
                    dominantBaseline="middle"
                    textAnchor="middle"
                  >
                    XXXXXXXXXX
                  </text>
                  <text
                    x="50%"
                    y="52%"
                    fill="#000000"
                    fontSize={cardBack.line1.fontSize}
                    dominantBaseline="middle"
                    textAnchor="middle"
                  >
                    {cardBack.line1.text}
                  </text>
                  <text
                    ref={lineTwoRef}
                    x="50%"
                    y="63%"
                    fill="#000000"
                    fontSize={cardBack.line2.fontSize}
                    dominantBaseline="middle"
                    textAnchor="middle"
                  >
                    {cardBack.line2.text}
                  </text>
                  <text
                    ref={lineThreeRef}
                    x="50%"
                    y="72%"
                    fill="#000000"
                    fontSize={cardBack.line3.fontSize}
                    dominantBaseline="middle"
                    textAnchor="middle"
                  >
                    {cardBack.line3.text}
                  </text>
                  <foreignObject
                    x="1%"
                    y="80%"
                    width="374"
                    height="100"
                    fontSize={cardBack.terms.fontSize}
                  >
                    <div className={styles.termsDigital}>{cardBack.terms.text}</div>
                  </foreignObject>
                </svg>
              </div>
            </div>

            <div className={styles.imageContainer} id="card-back-container">
              <div id="svg">
                <svg xmlns="http://www.w3.org/2000/svg" width="1088" height="713">
                  <rect x="0" y="0" width="1088" height="713" fill="#ffffff" />
                  <text
                    x="50%"
                    y="52%"
                    fill="#000000"
                    fontSize={cardBack.line1.sizePrint}
                    dominantBaseline="middle"
                    textAnchor="middle"
                  >
                    {cardBack.line1.text}
                  </text>
                  <text
                    x="50%"
                    y="63%"
                    fill="#000000"
                    fontSize={cardBack.line2.sizePrint}
                    dominantBaseline="middle"
                    textAnchor="middle"
                  >
                    {cardBack.line2.text}
                  </text>
                  <text
                    x="50%"
                    y="72%"
                    fill="#000000"
                    fontSize={cardBack.line3.sizePrint}
                    dominantBaseline="middle"
                    textAnchor="middle"
                  >
                    {cardBack.line3.text}
                  </text>
                  <foreignObject
                    x="2%"
                    y="80%"
                    width="1085"
                    height="140"
                    fontSize={cardBack.terms.sizePrint}
                  >
                    <div className={styles.terms}>{cardBack.terms.text}</div>
                  </foreignObject>
                </svg>
              </div>
            </div>
          </div>
          {OrderNavigation(navigateBack, prevLabel, captureCard, nextLabel)}
        </div>
      </div>
    </>
  )
}
