import React, { useCallback, useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import { Auth } from "aws-amplify"
import {
  AUTH_VERIFY_URL,
  CLOVER_CLIENT_ID,
  CLOVER_OAUTH_URL,
  CLOVER_REDIRECT_URI,
} from "../constants/localEnv"
import { LoadingSpinner } from "../utils/loadingSpinner"
import { getCookie } from "../utils/cookieHelper"
import { AppDispatchContext, AppStateContext } from "../context/appContext"
import { SET_IS_LOADING } from "../constants/actionTypes"

//direct to this page for clover login landing "pco.com/clover"
const isBrowser = () => typeof window !== "undefined"

const CloverPage = () => {
  const state = useContext(AppStateContext)
  const dispatch = useContext(AppDispatchContext)
  const isLoading = true
  const [attemptLogin, setAttemptLogin] = useState(false)
  if (isBrowser()) {
    // if PBC source redirect to index.js else clover users should land here
    document.cookie = "origin=clover" //origin=pbc

    Auth.currentAuthenticatedUser()
      .then(user => {
        navigate(redirectHandler())
      })
      .catch(async err => {
        setAttemptLogin(true)
        await handleCloverLogin()
          .then(user => {
            navigate(redirectHandler())
          })
          .catch(err2 => {
            if (process.env.NODE_ENV === "development" || process.env.GATSBY_SHOW_AMPLIFY_LOGIN) {
              navigate(redirectHandler())
            } else {
              navigate("/logged-out")
            }
          })
      })
  }

  const redirectHandler = () => {
    const search = window.location.search
    if (!search) return emailRedirect()
    const redirectionUri = new URLSearchParams(search).get("redirect")
    if (!redirectionUri) return emailRedirect()
    return redirectionUri
  }

  const emailRedirect = () => {
    let encodedTimestampKV = getCookie("encodedTimestamp")
    let emailRedirectKV = getCookie("emailRedirectURL")
    if (encodedTimestampKV && emailRedirectKV[1] === "order-card") {
      return `/app/orders/order-summary/#` + encodedTimestampKV[1]
    } else {
      return `/app/dashboard`
    }
  }

  const cloverLoginSpinner = active => {
    dispatch({
      type: SET_IS_LOADING,
      payload: active,
    })
  }

  useEffect(() => {
    if (
      state &&
      state.hasOwnProperty("loggedIn") &&
      typeof state.loggedIn !== "undefined" &&
      state.hasOwnProperty("isLoading") &&
      typeof state.isLoading !== "undefined"
    ) {
      if (!state.loggedIn && attemptLogin && !state.isLoading) {
        cloverLoginSpinner(true)
      } else if (state.loggedIn && state.isLoading) {
        cloverLoginSpinner(false)
      }
    }
  }, [state, attemptLogin])

  function userLogin(cognitoUser) {
    try {
      return new Promise((resolve, reject) => {
        if (cognitoUser) {
          const username = cognitoUser.userName
          const usernonce = cognitoUser.nonce.v
          Auth.signIn(username)
            .then(user => {
              if (
                "CUSTOM_CHALLENGE" === user.challengeName &&
                "pbc_jwt" === user.challengeParam.cookieName
              ) {
                Auth.sendCustomChallengeAnswer(user, usernonce)
                  .then(_user => {
                    resolve(_user)
                  })
                  .catch(error => {
                    console.log("Error while sendCustomChallengeAnswer : ", error)
                    setAttemptLogin(false)
                    reject(error)
                  })
              }
            })
            .catch(error => {
              console.log("Error while signIn : ", error)
              setAttemptLogin(false)
              reject(error)
            })
          return
        }
        const err = new Error("could not read required authentication details")
        reject(err)
      })
    } catch (e) {
      console.error("Error performing signin ", e)
      setAttemptLogin(false)
    }
  }

  const validateQueryParam = () => {
    if (window?.location?.search) {
      const clientId = new URLSearchParams(window.location.search).get("client_id")
      return !!clientId
    }
    return false
  }

  const handleCloverLogin = useCallback(async () => {
    if (validateQueryParam()) {
      const cloverAccessTokenUrl =
        (process.env.GATSBY_AUTH_VERIFY_URL || AUTH_VERIFY_URL) +
        window.location.search +
        "&clover_auth=1"
      try {
        const response = await fetch(cloverAccessTokenUrl)
        if (response.ok) {
          const cognitoUser = await response.json()
          console.log("Auth verify response : ", cognitoUser)
          return await userLogin(cognitoUser)
        } else {
          console.error("Error fetching token", response.status)
          setAttemptLogin(false)
        }
      } catch (e) {
        console.error("Error performing Auth Verify", e)
        setAttemptLogin(false)
      }
    } else {
      const qParams = [
        `client_id=${process.env.GATSBY_CLOVER_CLIENT_ID || CLOVER_CLIENT_ID}`,
        `redirect_uri=` +
          encodeURIComponent(
            window.location.href || process.env.GATSBY_CLOVER_REDIRECT_URI || CLOVER_REDIRECT_URI,
          ),
      ].join("&")
      try {
        let cloverOAuthUrl = process.env.GATSBY_CLOVER_OAUTH_URL || CLOVER_OAUTH_URL
        console.log(cloverOAuthUrl)
        window.location.replace(`${cloverOAuthUrl}?${qParams}`)
      } catch (e) {
        console.error("Error doing Clover Oauth", e)
      }
    }
  }, [])

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        "Clover Landing Page Placeholder - Redirect to clover login"
      )}
    </div>
  )
}

export default CloverPage
