import React, { useContext, useEffect, useState } from "react"
import { Button, FormControl, Grid, TextField } from "@material-ui/core"
import * as styles from "./settings.module.scss"
import { useStyles } from "./index"
import { formatZipcode } from "../creditCard/utils"
import { validateCharacters } from "../../utils/characterValidation"
import { validateAddressFields } from "../../utils/validateAddressFields"
import { SET_PROFILE_ADDRESS } from "../../constants/actionTypes"
import { AppDispatchContext } from "../../context/appContext"

const Address = ({ address, saveSettingsHandler, checkEmpty }) => {
  const classes = useStyles()
  const dispatch = useContext(AppDispatchContext)
  const [saveAddressButtonDisabled, setSaveAddressButtonDisabled] = useState(
    !address.shipAddress1 || !address.shipCity || !address.shipState || !address.shipZipCode,
  )
  const [updatedAddress, setUpdatedAddress] = useState(address)
  const [formErrors, setFormErrors] = useState({})

  const saveAddress = async () => {
    let address = updatedAddress
    address.default = updatedAddress.default === null ? true : updatedAddress.default
    await saveSettingsHandler(
      "shipAddress",
      [address],
      "The Shipping Address has been saved successfully",
    )
    dispatch({
      type: SET_PROFILE_ADDRESS,
      payload: {
        ...address,
      },
    })
    setSaveAddressButtonDisabled(true)
  }

  const clearNumber = value => {
    return value.replace(/\D+/g, "")
  }

  const clearAlpha = value => {
    return value.replace(/[^a-zA-Z. ]/g, "")
  }

  const addressInputChange = event => {
    const { name, value, type } = event.target
    let clearValue = value
    if (type === "tel") {
      clearValue = clearNumber(value)
    }
    if (type === "text" && name === "shipState") {
      clearValue = clearAlpha(value)
    }
    if (name === "shipZipCode") {
      clearValue = value.replace(/-/g, "")
    }

    //Since this is a non-required field we wil check it at time of entry
    if (name === "shipAddress1" || name === "shipAddress2") {
      if (/\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/i.test(value.trim())) {
        setFormErrors({ ...formErrors, [name]: "P.O. Boxes are not permitted." })
      } else {
        let removePOBoxError = { ...formErrors }
        if (removePOBoxError[name]) {
          delete removePOBoxError[name]
        }
        setFormErrors(removePOBoxError)
      }
    }

    setUpdatedAddress({
      ...updatedAddress,
      [name]: clearValue,
    })
  }

  const addressInputBlur = event => {
    const { name, value, attributes } = event.target
    const error_text = validateAddressFields(name, value, attributes)
    setFormErrors({ ...formErrors, [name]: error_text })
  }

  useEffect(() => {
    const checkEmptyFormErrors = () => {
      let isEmpty = false

      if (formErrors) {
        for (const errorKey in formErrors) {
          if (formErrors[errorKey] && formErrors[errorKey] !== undefined) {
            isEmpty = true
            break
          }
        }
      }

      return isEmpty
    }

    setSaveAddressButtonDisabled(checkEmpty("[required]") || checkEmptyFormErrors())
  }, [checkEmpty, formErrors])

  return (
    <Grid container spacing={3} className={classes.settingsSubSection}>
      <Grid item xs={12}>
        <h4 className={styles.subSectionTitle} data-test="settings-page-title">
          Shipping Address
        </h4>
        <h5 className={styles.h5Style}>Please Note: we do not ship to PO Boxes.</h5>
        <FormControl variant="filled" className={styles.fullWidth}>
          <TextField
            className={`label`}
            type={`text`}
            id={`label`}
            name={`label`}
            variant="filled"
            label={`Label`}
            fullWidth={true}
            inputProps={{ maxLength: 30 }}
            onChange={addressInputChange}
            onBlur={addressInputBlur}
            key={address.label}
            value={updatedAddress.label || ""}
            required
            helperText={formErrors.label}
            error={formErrors.label ? true : undefined}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="filled" className={styles.fullWidth}>
          <TextField
            className={`shipping_fields`}
            type={`text`}
            id={`shipAddress1`}
            name={`shipAddress1`}
            variant="filled"
            label={`Address 1`}
            fullWidth={true}
            inputProps={{ maxLength: 30 }}
            onChange={addressInputChange}
            onBlur={addressInputBlur}
            key={address.shipAddress1}
            value={updatedAddress.shipAddress1 || ""}
            required
            helperText={formErrors.shipAddress1}
            onKeyDown={validateCharacters}
            error={formErrors.shipAddress1 ? true : undefined}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="filled" className={styles.fullWidth}>
          <TextField
            className={`shipping_fields`}
            type={`text`}
            id={`shipAddress2`}
            name={`shipAddress2`}
            variant="filled"
            label={`Address 2`}
            fullWidth={true}
            inputProps={{ maxLength: 30 }}
            onChange={addressInputChange}
            onBlur={addressInputBlur}
            key={address.shipAddress2}
            value={updatedAddress.shipAddress2 || ""}
            helperText={formErrors.shipAddress2}
            onKeyDown={validateCharacters}
            error={formErrors.shipAddress2 ? true : undefined}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl variant="filled" className={styles.fullWidth}>
          <TextField
            className={`shipping_fields`}
            type={`text`}
            id={`shipCity`}
            name={`shipCity`}
            variant="filled"
            label={`City`}
            fullWidth={true}
            inputProps={{ maxLength: 30 }}
            onChange={addressInputChange}
            onBlur={addressInputBlur}
            key={address.shipCity}
            value={updatedAddress.shipCity || ""}
            required
            helperText={formErrors.shipCity}
            onKeyDown={validateCharacters}
            error={formErrors.shipCity ? true : undefined}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl variant="filled" className={styles.fullWidth}>
          <TextField
            className={`shipping_fields`}
            type={`text`}
            id={`shipState`}
            name={`shipState`}
            variant="filled"
            label={`State`}
            fullWidth={true}
            inputProps={{ maxLength: 2 }}
            onChange={addressInputChange}
            onBlur={addressInputBlur}
            key={address.shipState}
            value={updatedAddress.shipState || ""}
            required
            helperText={
              formErrors.shipState
                ? formErrors.shipState
                : "Enter a 2 Letter State Code eg. CA or MA"
            }
            error={formErrors.shipState ? true : undefined}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl variant="filled" className={styles.fullWidth}>
          <TextField
            className={`additional_fields`}
            type={`text`}
            id={`shipCountry`}
            name={`shipCountry`}
            variant="filled"
            label={`Country`}
            fullWidth={true}
            inputProps={{ maxLength: 30 }}
            onChange={addressInputChange}
            onBlur={addressInputBlur}
            disabled={true}
            defaultValue={`United States`}
            // helperText={formErrors.shipCountry}
            // error={formErrors.shipCountry ? true : undefined}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl variant="filled" className={styles.fullWidth}>
          <TextField
            className={`shipping_fields`}
            id={`shipZipCode`}
            name={`shipZipCode`}
            variant="filled"
            label={`Postal Code`}
            fullWidth={true}
            inputProps={{ maxLength: 10, minLength: 5 }}
            onChange={addressInputChange}
            onBlur={addressInputBlur}
            key={address.shipZipCode}
            value={formatZipcode(updatedAddress.shipZipCode) || ""}
            required
            helperText={formErrors.shipZipCode}
            error={formErrors.shipZipCode ? true : undefined}
          />
        </FormControl>
      </Grid>
      <Grid container className={classes.settingsAddressButtonGrid}>
        <Button
          variant="contained"
          color="primary"
          id="save-address-button"
          className="buttonPrimary"
          onClick={saveAddress}
          disabled={saveAddressButtonDisabled}
        >
          Save Address
        </Button>
      </Grid>
    </Grid>
  )
}

export default Address
